import React, { useState, useEffect } from 'react';
import {
  Configure,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  SearchBox,
  ToggleRefinement,
} from 'react-instantsearch';
import { debounce } from 'lodash';
//import { usePostHog } from 'posthog-js/react';

import type { Hit } from 'instantsearch.js';

import './App.css';


const future = { preserveSharedStateOnUnmount: true };

function formatNumberToString(value: number): string {
  // Determine the magnitude suffix based on the value
  let suffix = '';
  if (value >= 1e9) {
    value /= 1e9;
    suffix = 'B';
  } else if (value >= 1e6) {
    value /= 1e6;
    suffix = 'M';
  } else if (value >= 1e3) {
    value /= 1e3;
    suffix = 'k';
  }

  // Format the value with appropriate precision
  let formattedValue = value.toLocaleString(undefined, {
    minimumFractionDigits: value % 1 === 0 ? 0 : 1,
    maximumFractionDigits: value % 1 === 0 ? 0 : 1
  });

  // Append the suffix
  return formattedValue + suffix;
}


type HitProps = {
  hit: Hit;
};

function isValidJsonString(str: string): boolean {
  if (str.trim() === '') {
    return false; // Empty string
  }

  try {
    JSON.parse(str);
    return true; // Valid JSON
  } catch (error) {
    return false; // Invalid JSON
  }
}

export function App({ searchClient }) {
  const posthog = null //usePostHog();
  const [hasFocusedSearch, setHasFocusedSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 600)

    console.info("Loaded version 1.0.0")
  }, []);

  const debouncedSearch = debounce((query, refine) => {
    refine(query);
    posthog?.capture("search", { "query": query });
  }, 300);

  const handleSearch = (query, refine) => {
    setHasFocusedSearch(true);
    debouncedSearch(query, refine);
  };

  // may be inefficient to declare within the parent.
  function Hit({ hit }: HitProps) {
    // console.error({ hit })
    let socialLinks;
    if (isValidJsonString(hit.server__social_links)) {
      const socialLinksRaw = JSON.parse(hit.server__social_links)
      if (isMobile) {
        socialLinks = socialLinksRaw.map((socialLink) => {
          // [{"reach": 14041, "url": "https://twitter.com/xblafans", "channel": "Twitter"}]
          return (
            <a key={socialLink.url} className="social-link" href={socialLink.url} rel="noreferrer" target="_blank">
              {socialLink.channel} {formatNumberToString(socialLink.reach)}
            </a>
          )
        })
      } else {
        socialLinks = socialLinksRaw.map((socialLink) => {
          // [{"reach": 14041, "url": "https://twitter.com/xblafans", "channel": "Twitter"}]
          return (
            <a key={socialLink.url} className="social-link" href={socialLink.url} rel="noreferrer" target="_blank">
              {socialLink.channel} {formatNumberToString(socialLink.reach)}
            </a>
          )
        })
      }
    } else {
      socialLinks = [];
    }
    function navigateToServerUrl() {
      posthog?.capture("join", { "server": hit.server__name, "url": hit.server__invite_url });
      // console.error(JSON.stringify({ "join": { "server": hit.server__name, "url": hit.server__invite_url } }))
      window.open(hit.server__invite_url, "_blank");
    }

    return isMobile ? (
      <div className="mobile-result-item">
        <Highlight attribute="server__name" hit={hit} className="result-name" />
        <div className="result-size" >
          {formatNumberToString(hit.server__size)}{" "}Members
        </div>
        <Highlight attribute="server__description" hit={hit} className="result-description" />
        {socialLinks.length > 0 && (
          <div className='social-links'>
            {socialLinks}
          </div>)}
        <button className="join-button" id={hit.server__name} onClick={navigateToServerUrl}>JOIN</button>
      </div>
    ) : (
      <tr>
        <td>
          <button className="join-button" id={hit.server__name} onClick={navigateToServerUrl}>JOIN</button>
        </td>
        <td style={{ minWidth: "180px", maxWidth: "180px", marginTop: "12px", marginBottom: "12px" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Highlight attribute="server__name" hit={hit} className="result-name" />
            <div className="result-size" >
              {formatNumberToString(hit.server__size)}{" "}Members
            </div>
            {/* <LastUpdated>last updated: 4 hours ago</LastUpdated> */}
          </div>
        </td>
        <td>
          <Highlight attribute="server__description" hit={hit} className="result-description" />
        </td>
        <td style={{ paddingRight: "0px", minWidth: "100px" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            {socialLinks}
          </div>
        </td>
      </tr>
    )
  }

  return (
      <InstantSearch
        searchClient={searchClient}
        indexName="discord_servers"
        future={future}
      >
        <Configure hitsPerPage={8} />
        <div className="header">
          <h1 className="brand">Find Discord Servers</h1>
          <div className="search-input-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="search-icon"><circle cx="11" cy="11" r="8" /><line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
            <SearchBox queryHook={handleSearch} placeholder="Search Servers" />
          </div>
        </div>
        {hasFocusedSearch ? (
          <>
            <div className="filter-bar">
              <ToggleRefinement attribute="server__genre_ai" label="Show AI Servers Only" on={true} />
            </div>
            <div className="results-container">
              <Hits hitComponent={Hit} classNames={{ root: "style-unsetter", emptyRoot: "style-unsetter", list: "style-unsetter", item: "style-unsetter" }} />
              <div className="pagination">
                <Pagination showNext={false} padding={2} />
              </div>
            </div>
          </>
        ) : (<div className="seo-text">
          <h2 className="h2-info">Find a great community to join, in seconds. </h2>
          <p className="p-info">
            Welcome! We connect you to vibrant Discord servers across the globe. Whether you are an avid gamer, a diligent coder, a music enthusiast, a sports fan, or a finance expert, we provide an array of choices to indulge in discussions, collaborations or just simple friendly chats. Our platform has been designed in such a way that locating your ideal discord community is a breeze.
            <br />
            <br />
            Our selection covers servers for gamers to dive into the most action-packed adventures, for coders to learn, collaborate and share their knowledge, and for music buffs to discuss and discover new music. We also include communities for sports enthusiasts to share insights, predictions and highlights, and financial platforms for eager investors to stay updated on market trends. Simply search, join, and interact with like-minded folks in our diverse range of Discord servers.
          </p>
        </div>)}
      </InstantSearch>
  );
}

export default App;
