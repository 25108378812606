import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
//import { PostHogProvider } from 'posthog-js/react'
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

/*
const posthogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}
*/

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: parseInt(process.env.REACT_APP_TYPESENSE_PORT),
        protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
      }
    ]
  },
  additionalSearchParameters: {
    query_by: "server__name,server__description",
    sort_by: 'server__size:desc',
  }
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY!}
      options={posthogOptions}
    > */}
    <App searchClient={searchClient} />
    {/* </PostHogProvider> */}
  </React.StrictMode>
);
